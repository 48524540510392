import React, { Component } from 'react';
import './App.css';
import HeroImg from './img/eclipse-hero.jpg';
import LogoImg from './img/logo.svg';
import LogoBrandImg from './img/logo-brand.svg';

class App extends Component {
  render() {
    return (
      <div className="App">
        <nav className="navbar navbar-expand-md mb-2 rounded">
          <a className="navbar-brand" href="#"><img src={ LogoImg } width="100px" height="48px" alt="Eclipse Technology" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* <div className="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#">Blog <span className="sr-only">(current)</span></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Contact</a>
              </li>
            </ul>
          </div>   */}
            
        </nav>
        <div className="jumbotron">
          <div className="container">
            <h1 className="display-3">Do Better.</h1>
            {/* <p>Sign up to hear more about how Eclipse Technology is going to <br />change the landscape forever.</p> */}
            <p>Be prepared for Eclipse Technology to <br />change the landscape forever.</p>
          </div>      
        </div>
        <div className="mainContainer container">
          <div className="row">
            <article className="col-md-7">
              <h2>Stellar technology is no longer an optional competency. It is a necessity.</h2>
              <p>
                The ability to innovate is a top priority for companies around the globe. The capacity to
                 develop and deploy the most modern and secure technology 
                possible is how leaders are conquering their domains, and succeeding.
                </p>
                <p>The strategic and tactical solutions team at Eclipse Technology was borne of this reality. Powered by the agile, cutting-edge technology and the capable team spirit that built expert
                 solutions for the insurance and financial services arenas, Eclipse Technology provides strategic 
                 systems consulting, custom interfaces, and engineering prowess to clients and partners, and to 
                 companies beyond.
              </p>
            </article>
            <div className="col-md-5 sidebar">
              <div className="row">
                <div className="col text-center"><i className="fas fa-rocket"></i></div>
                <div className="col col-sm-8 px-0">
                  <h2>Adapt</h2>
                  <p>Eclipse Technology supplies solutions built with today's technology and readily adaptable to the future.</p>
                </div>
              </div>
              
              <div className="row">
                <div className="col text-center"><i className="fas fa-fire"></i></div>
                <div className="col col-sm-8 px-0">
                  <h2>Survive</h2>
                  <p>Eclipse Technology specializes in systems that are 100% web-based, 100% cloud-based, 100% real-time, as well as applications for today's vast array of mobile devices.</p>
                </div>
              </div>

              <div className="row">
                <div className="col text-center"><i className="fas fa-cloud"></i></div>
                <div className="col col-sm-8 px-0">
                  <h2>Thrive</h2>
                  <p>Eclipse Technology optimizes technology to meet vital marketplace demands, increasing security, productivity & profit.</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-2 text-center">
                <a className="navbar-brand" href="#"><img src={ LogoBrandImg } width="60px" height="71px" alt="Eclipse Technology" /></a>
                <div className="copyright">© Eclipse Technology 2019</div>
              </div>
              <div className="col-md-2">
                <nav>
                  {/* <ul className="footerLinks">
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Contact</a></li>
                    <li><a href="#">Terms &amp; Conditions</a></li>
                  </ul> */}
                </nav>
              </div>
              <div className="col-md-2">
                {/* <ul className="socialLinks">
                  <li><a href="#"><i className="fab fa-facebook"></i> Facebook</a></li>
                  <li><a href="#"><i className="fab fa-twitter"></i> Twitter</a></li>
                  <li><a href="#"><i className="fab fa-instagram"></i> Instagram</a></li>
                </ul> */}
              </div>
              {/* <div className="col-md-3 newsletter">
                Subscribe to our newsletter
              </div> */}
              <div className="col-md-6 address text-right">
              <address>
              100 Financial Dr. 
              Suite 300 
              Kalispell, MT 59901 <br />
              +1 406 480 6366 <br />
              <a href="mailto:contact@eclipse.tech">contact@eclipse.tech</a>
              </address>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
